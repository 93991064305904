import { Injectable } from '@angular/core';
import { interval, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  constructor() { }
  date = new Date();

  getDate() {
    return interval(1000).pipe(map(_ => this.getDateTime()));
  }

  // get new time by adding + sec
  private getDateTime() {
    this.date.setSeconds(this.date.getSeconds() + 1);
    return (
      this.formatTimeUnitWithLeadingZero(this.date.getHours()) +
      ':' +
      this.formatTimeUnitWithLeadingZero(this.date.getMinutes()) +
      ':' +
      this.formatTimeUnitWithLeadingZero(this.date.getSeconds())
    );
  }

  getTime() {
    return interval(1000).pipe(map(_ => Date.now()));
   }

  formatHoursAndMinutes(timeInMilli: number) {
    if (timeInMilli == undefined || timeInMilli == 0) {
      return '00:00';
    }
    let totalHours = ((timeInMilli) / (1000 * 60 * 60));
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);   
    const formattedHours = this.formatTimeUnitWithLeadingZero(hours);
    const formattedMinutes = this.formatTimeUnitWithLeadingZero(minutes);
    return `${formattedHours}:${formattedMinutes}`;
  }

  formatTimeUnitWithLeadingZero(units: number) {
    return units < 10 ? '0' + units : units;
  }

}
